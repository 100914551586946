export function datetimeLocal(datetime: Date) {
  const dt = new Date(datetime);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  return dt.toISOString().slice(0, 16);
}

export function getHourFromDate(milliseconds: number) {
  return ("0" + new Date(milliseconds).getHours()).substr(-2);
}

export function getMinutesFromDate(milliseconds: number) {
  return ("0" + new Date(milliseconds).getMinutes()).substr(-2);
}

export function verifyEnvironment(envVars: string[]) {
  const missingVars: string[] = [];

  envVars.forEach((v) => {
    if (!process.env[v]) {
      missingVars.push(v);
    }
  });

  if (missingVars.length > 0) {
    throw new Error("Environment Variables missing: " + missingVars.join(", "));
  }
}

export function getSlotsText(slots: number) {
  return slots === 1 ? slots + " Platz" : slots + " Plätze";
}

export function getFreeSlotsDisplay(slots: number, slotsSold: number) {
  return Math.max(0, slots - slotsSold);
}

export function applyColorTheme(
  primaryColor: string,
  secondaryColor: string,
  primaryDarkColor: string,
  secondaryDarkColor: string
) {
  document.documentElement.style.setProperty("--primary-color", primaryColor);
  document.documentElement.style.setProperty(
    "--primary20-color",
    primaryColor + "20"
  );
  document.documentElement.style.setProperty(
    "--primary60-color",
    primaryColor + "60"
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    secondaryColor
  );
  document.documentElement.style.setProperty(
    "--primaryDark-color",
    primaryDarkColor
  );
  document.documentElement.style.setProperty(
    "--secondaryDark-color",
    secondaryDarkColor
  );
}

export function isValidEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
