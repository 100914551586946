import axios from "axios";

let tokenExpiredMessage: string;
let doRefreshAccessToken: () => Promise<number>;

axios.interceptors.response.use(null, async (error) => {
  logUnexpectedErrors(error);

  if (error.response) {
    const { status, data } = error.response;

    switch (status) {
      case 401:
        if (tokenExpiredMessage && data === tokenExpiredMessage) {
          try {
            const status = await doRefreshAccessToken();

            if (status === 200) {
              const config = error.config;
              delete config.headers["Authorization"];

              return await axios(config);
            } else {
              return Promise.reject(error);
            }
          } catch (e) {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);

      default:
        return Promise.resolve(error);
    }
  } else return Promise.resolve(error);
});

function setAuthAccessToken(jwt: string) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

function clearAuthAccessToken() {
  axios.defaults.headers.common["Authorization"] = "";
}

function setAuthRefreshTokenFunc(func: () => Promise<number>) {
  doRefreshAccessToken = func;
}

function setTokenExpiredMessage(msg: string) {
  tokenExpiredMessage = msg;
}

function logUnexpectedErrors(error: any) {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) console.error(error); // Log unexpected errors somewhere else
}

// TODO: hier wird nicht gehandlet?
// wenn dann eher logError?
// sollte eigentlich weg kommen
// und anstelle von console.error was auf dem server ablegen
function handleError(error: any) {
  console.error(error);

  if (error.response && error.response.status) {
    return error.response.status;
  } else {
    return 999;
  }
}

const exportObject = {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
  setAuthAccessToken,
  setAuthRefreshTokenFunc,
  clearAuthAccessToken,
  setTokenExpiredMessage,
  handleError,
};

export default exportObject;
