import LoadingIndicator from "../common/LoadingIndicator";
import PayPalButtonWrapper, {
  OrderStatus,
  PayPalButtonWrapperItems,
} from "../common/PayPalButtonWrapper";
import { TicketBuyIntent } from "../event-ticket/TicketItem";

interface CheckoutOrderButtonsProps {
  eventId: string;
  tickets: TicketBuyIntent[];
  handleError: (error: Record<string, unknown>) => void;
  handleCancel: (data: Record<string, unknown>) => void;
  handleValidate: (
    eventId: string,
    tickets: TicketBuyIntent[],
    orderId: string
  ) => Promise<boolean>;
  handleApprove: (
    eventId: string,
    tickets: TicketBuyIntent[],
    orderId: string
  ) => void;
}

const CheckoutOrderButtons = (props: CheckoutOrderButtonsProps) => {
  const totalPrice = props.tickets.reduce(
    (acc, ticket) => acc + ticket.price * ticket.amount,
    0
  );

  if (totalPrice === 0) {
    return (
      <button
        className="btn-primary mb-2 mt-1"
        onClick={
          () => props.handleApprove(props.eventId, props.tickets, "FREE_PRICE") // TODO use a common constant
        }
      >
        Bestellen
      </button>
    );
  }

  const paypalPurchaseItems: PayPalButtonWrapperItems[] = props.tickets.map(
    (ticket) => {
      return {
        name: ticket.name,
        quantity: ticket.amount,
        price: ticket.price,
      };
    }
  );

  return (
    <PayPalButtonWrapper
      currency_code="EUR"
      items={paypalPurchaseItems}
      onValidate={(orderId: string) =>
        props.handleValidate(props.eventId, props.tickets, orderId)
      }
      onCancel={props.handleCancel}
      onError={props.handleError}
      onApprove={(orderId: string, orderStatus: OrderStatus) =>
        props.handleApprove(props.eventId, props.tickets, orderId)
      }
      LoadingIndicator={<LoadingIndicator show={true} />}
    />
  );
};

export default CheckoutOrderButtons;
