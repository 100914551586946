import { ComponentProps, forwardRef } from "react";
import { FieldError } from "./ValidatableForm";

interface ValidatableFormFieldProps extends ComponentProps<"input"> {
  label: string;
  additionalClasses: string;
}

const ValidatableFormField = forwardRef<
  HTMLInputElement,
  ValidatableFormFieldProps
>(function ValidatableFormField(
  { label, additionalClasses = " ", type = "text", ...props },
  ref
) {
  const renderInput = () => {
    return (
      <div className="mb-2 flex flex-col gap-1">
        <label>{label}</label>
        <input
          type={type}
          ref={ref}
          {...props}
          className={
            "rounded-[10px] border-[1px] border-primaryDark px-4 py-1 focus:outline-primaryDark" +
            " " +
            additionalClasses
          }
        />
        <FieldError name={props.name} />
      </div>
    );
  };

  const renderInputTextArea = () => {
    return (
      <div className="mb-2 flex flex-col">
        <label>{label}</label>
        <textarea
          type={type}
          //@ts-ignore
          ref={ref}
          //@ts-ignore
          onInput={(e) => autoGrow(e)}
          {...props}
          className={
            "mt-1 min-h-[200px] resize-none overflow-hidden rounded-[10px] border-[1px] border-primaryDark px-4 py-1 focus:outline-primaryDark" +
            " " +
            additionalClasses
          }
        />
        <FieldError name={props.name} />
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <input
        type={type}
        value={label}
        className={"btn-primary mb-2 mt-1" + " " + additionalClasses}
      />
    );
  };

  if (
    type === "text" ||
    type === "number" ||
    type === "email" ||
    type === "password" ||
    type === "datetime-local"
  )
    return renderInput();
  else if (type === "textarea") return renderInputTextArea();
  else if (type === "submit") return renderSubmitButton();
  else return <></>;
});

const autoGrow = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  event.currentTarget.style.height = "5px";
  event.currentTarget.style.height = event.currentTarget.scrollHeight + "px";
};

export default ValidatableFormField;
